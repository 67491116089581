import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import brandBuild from 'assets/file/brandBuild.json';
import { SentryInfo, SentryLevel, ignorableErrors } from 'utils/sentry';

const brandRelease = `BackOffice_${import.meta.env.MODE.toUpperCase()}@${
  brandBuild.version
}`;

if (
  !import.meta.env.MODE.toLowerCase().includes('local') &&
  !import.meta.env.MODE.toLowerCase().includes('development')
) {
  Sentry.init({
    dsn: 'http://80289e7108bd094608097d546d396b66@stry.mgc.pw/5',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    release: `${brandRelease}`,
    environment: import.meta.env.VITE_SENTRY_ENV || 'LOCAL',
    ignoreErrors: ignorableErrors,
    initialScope: (scope) => {
      scope.setTags({ errorType: 'UNHANDLED ERROR' });
      scope.setLevel(SentryLevel.Fatal);
      scope.setTags(SentryInfo().tags);
      scope.setUser(SentryInfo().user);

      return scope;
    },
    tracesSampleRate: 1.0,
  });
}
